

































































































































































































































import { IEstimationRes } from "@/core/Orders/OrdersUseCases";
import { IAmount, ICashBalance, IOrderSide, IOrderType } from "@/core/types";
import { ICompany } from "@/core/types/Company";
import { Component, Vue, Watch } from "vue-property-decorator";
import CompanyLogo from "../components/base/CompanyLogo.vue";
import InfoIcon from "vue-material-design-icons/InformationOutline.vue";
import { MarketStatus } from "@/core";
import OrderError from "../components/orders/OrderError.vue";
import DetailCell from "@/components/global/DetailCell.vue";
type BuyOrderSteps = "first_step" | "second_step" | "success" | "error";

@Component({
  components: {
    CompanyLogo,
    InfoIcon,
    OrderError,
  },
})
export default class extends Vue {
  loading = true;
  shares = 1;
  step: BuyOrderSteps = "first_step";
  sentBuyOrder = {};
  loadingSendBuyOrder = false;

  get prices(): IEstimationRes {
    return this.$store.state.buy.prices;
  }
  get estimate(): IEstimationRes {
    return this.$store.state.buy.estimate;
  }
  get estimatedTotal(): IAmount {
    if (this.step === "first_step") {
      const value =
        this.prices.costInClientsCurrencyWithCommission.value * this.shares;
      return {
        value,
        currency: this.prices.costInClientsCurrencyWithCommission.currency,
      };
    } else {
      return this.estimate.totalCost;
    }
  }
  get company(): ICompany {
    return this.$store.state.buy.company;
  }
  get subtotal(): IAmount {
    return {
      value: this.prices.assetPrice.value.value * this.shares,
      currency: this.prices.assetPrice.value.currency,
    };
  }
  get cashBalance(): ICashBalance {
    return this.$store.state.buy.cashBalance;
  }
  get orderSide(): IOrderSide {
    return "BUY";
  }
  get orderType(): IOrderType {
    return "MARKET";
  }
  get reviewButtonDisabled(): boolean {
    return this.cashBalanceExceeded || this.shares <= 0;
  }

  // Methods
  async clickReview(): Promise<void> {
    this.loading = true;
    if (this.shares < 0) {
      throw new Error('"shares" must be greater than 0');
    }
    // fetch estimate for user config
    await this.$store.dispatch("buy/fetchEstimate", {
      assetId: this.$route.params.id,
      shares: this.shares,
      orderSide: this.orderSide,
      orderType: this.orderType,
    });
    // we add a route change so users can use the back button to go back to the first step.
    // s=2 = step 2
    this.$router.push("?s=" + 2);
    this.loading = false;
    // show second step
    this.step = "second_step";
  }

  async sendBuyOrder(): Promise<void> {
    this.loadingSendBuyOrder = true;
    const config = {
      assetId: this.$route.params.id,
      shares: this.shares,
      orderSide: this.orderSide,
      orderType: this.orderType,
    };

    try {
      const res = await this.$store.dispatch("buy/sendBuyOrder", config);
      this.sentBuyOrder = res;
      this.step = "success";
    } catch (error) {
      this.step = "error";
    }

    this.loadingSendBuyOrder = false;
  }

  backToProductPage(): void {
    this.$router.push({
      name: "product",
      params: {
        id: this.$route.params.id,
      },
    });
  }

  tryAgain(): void {
    window.location.reload();
  }

  get marketIsOpen(): boolean {
    return MarketStatus.open;
  }

  openBuyHelpModal(): void {
    const modalComponent = this.marketIsOpen
      ? () => import("../components/orders/OpenMarketHelpModal.vue")
      : () => import("../components/orders/ClosedMarketHelpModal.vue");

    this.$store.dispatch("modal/setComponent", modalComponent);
    this.$store.dispatch("modal/show");
  }

  // Hooks
  async mounted(): Promise<void> {
    this.loading = true;
    await this.$store.dispatch("buy/loadData", this.$route.params.id);
    this.loading = false;
  }

  get exceeded(): boolean {
    return this.estimatedTotal.value > this.cashBalance.cashBalance.value;
  }

  get cashBalanceExceeded(): boolean {
    return this.estimatedTotal.value > this.cashBalance.cashBalance.value;
  }

  // Dirty hack so the back button on the browser returns you to the first step
  @Watch("$route.query.s")
  onStepChange(v: string): void {
    if (!v) {
      this.step = "first_step";
    }
  }
}
